<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-range-picker v-model:value="date" valueFormat="YYYY-MM-DD" />
            <a-input v-model:value="params.username" placeholder="请输入用户名" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
export default defineComponent({
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            submitUserOptions: [],
            teamOptions: [],
            processOptions: [],
            date: [],
            params: {
                username: undefined,
            },
        });

        const reset = () => {
            state.params.username = undefined;
            state.date = [];
            search();
        };

        const search = () => {
            state.params.username = state.params.username?.trim();
            let params = state.params;
            if (state.date.length) {
                let [createdTimeStart, createdTimeEnd] = state.date;
                params = {
                    ...params,
                    createdTimeStart: `${createdTimeStart} 00:00:00`,
                    createdTimeEnd: `${createdTimeEnd} 23:59:59`,
                };
            }
            emit("search", params);
        };

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
